<template>
  <card title="成果展示" :withbg="true">
    <el-row class="p-achieve-list" :gutter="20">
      <el-col :span="6" v-for="item of showList" :key="item.code">
        <div class="achieve-col">
          <p>
            <span class="num">{{ item.num }}</span>
            <span class="unit">{{ item.unit }}</span>
          </p>
          <p class="dec">
            <img :src="item.icon" />
            {{ item.name }}
          </p>
        </div>
      </el-col>
    </el-row>
  </card>
</template>

<script>
import Card from "@/components/Card/index.vue";
import {
  SelectOrder,
  SelectCompanyData
  // getZSNHZZ, getTDMJJYJE, getSLZT
 } from "@/api/displayResults.js";
// import { getCountTrustShip } from "@/api/add.js";
import { mapGetters } from "vuex";
const showList = [
  {
    code: 1,
    num: 0,
    unit: "宗",
    icon: require("@/assets/icon-show-8.png"),
    name: "累计托管宗数",
  },
  {
    code: 2,
    num:0,
    unit: "亩",
    icon: require("@/assets/icon-show-2.png"),
    name: "累计托管土地面积",
  },
  {
    code: 3,
    num: 0,
    unit: "万元",
    icon: require("@/assets/icon-show-3.png"),
    name: "累计托管交易金额",
  },
  {
    code: 4,
    num: 0,
    unit: "家",
    icon: require("@/assets/icon-show-4.png"),
    name: "红名单主体",
  },
  {
    code: 5,
    num: 0,
    unit: "家",
    icon: require("@/assets/icon-show-9.png"),
    name: "累计托管农户",
  },
  {
    code: 6,
    num: 0,
    unit: "家",
    icon: require("@/assets/icon-show-5.png"),
    name: "累计托管组织",
  },
  {
    code: 7,
    num: 0,
    unit: "家",
    icon: require("@/assets/icon-show-6.png"),
    name: "新型经营主体",
  },
  {
    code: 8,
    num: 0,
    unit: "家",
    icon: require("@/assets/icon-show-7.png"),
    name: "黑名单主体",
  },
];

export default {
  components: {
    Card,
  },
  computed: mapGetters(["topSelectAreacode"]),
  data() {
    return {
      showList,
    };
  },
  watch:{
    topSelectAreacode(){
      this.init();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // this.getTGZS();
      // this.getLJTGOne();
      // this.getZT();
      this.getOne()
      this.getTwo()
    },
    async getOne(){
      let res=await SelectOrder({
        areaCode:this.topSelectAreacode
      })
      // console.log(res,'查看one的数据')
        if(res.code==0){
          this.showList[0].num=res.data.orderNumber? res.data.orderNumber:0
          this.showList[1].num=res.data.totalArea? res.data.totalArea:0
          this.showList[2].num=res.data.totalAmount?( res.data.totalAmount/10000).toFixed(2):0
          this.showList[4].num=res.data.farmerNumber? res.data.farmerNumber:0
          this.showList[5].num=res.data.companyNumber? res.data.companyNumber:0
        }
    },
    async getTwo(){
      let res= await  SelectCompanyData({
          areaCode:this.topSelectAreacode
        })

         if(res.code==0){
            this.showList[6].num =res.data? res.data :0
         }

    }
    // async getTGZS() {
    //   let res = await getCountTrustShip({
    //     areaCode:this.topSelectAreacode
    //   });
    //   if (!res || res.code != 0) {
    //     return;
    //   }
    //   let data = res.data;
    //   // 不保留两位小数
    //   this.showList.forEach((item) => {
    //     switch (item.code) {
    //       case 6:
    //         item.num = data.companyNumber ? data.companyNumber : 0;
    //         break;
    //       case 1:
    //         item.num = data.orderTitleNumber ? (data.orderTitleNumber*1).toFixed(0) : 0;
    //         break;
    //       case 5:
    //         item.num = data.orderNumber ? (data.orderNumber*1).toFixed(0) : 0;
    //         break;
    //     }
    //   });
    // },
    // async getLJTGOne() {
    //   let res = await getTDMJJYJE({
    //     areaCode:this.topSelectAreacode
    //   });
    //   if (!res || res.code != 0) {
    //     return;
    //   }
    //   let data = res.data;
    //   this.showList.forEach((item) => {
    //     switch (item.code) {
    //       case 2:
    //         item.num = data.totalArea ? data.totalArea.toFixed(2) : 0;
    //         break;
    //       case 3:
    //         item.num = data.totalPrice ? (data.totalPrice/10000).toFixed(2) : 0;
    //         break;
    //     }
    //   });
    // },
    // async getLJTGTwo() {
    // },
    // async getZT() {
    //   let res = await getSLZT({
    //     areaCode:this.topSelectAreacode
    //   });
    //   if (!res || res.code != 0) {
    //     return;
    //   }
    //   let data = res.data;
    //   this.showList.forEach((item) => {
    //     switch (item.code) {
    //       case 4:
    //         item.num = data.redListTotal ? data.redListTotal : 0;
    //         break;
    //       case 7:
    //         item.num = data.total ? data.total : 0;
    //         break;
    //       case 8:
    //         item.num = data.blackListTotal ? data.blackListTotal : 0;
    //         break;
    //     }
    //   });
    // },

  },
};
</script>

<style lang="less" scope>
.p-achieve-list {
  height: 350px;
  .achieve-col {
    background: #fff;
    border: 1px solid #dcdcdc;
    padding: 10px 0px 20px 50px;
    margin: 15px 0;
    p {
      padding: 0;
      margin: 0;
    }

    .num {
      display: inline-block;
      max-width: 280px;
      font-size: 48px;
      color: #333;
    }

    .dec {
      font-size: 18px;
      color: #666;
      display: flex;
      align-items: center;

      img {
        margin-right: 6px;
      }
    }
  }
}
</style>
