<!-- 托管面积占比 -->
<template>
  <LandCard title="托管面积占比" :isLeftMore="false">
    <div class="echartStyle">
      <chart :options="options"></chart>
    </div>
  </LandCard>
</template>

<script>
import LandCard from "@/components/Card/landTrustCard.vue";
import chart from "@/components/echart/echart";
import { mapGetters } from "vuex";
//调用数据
import { getTGMJInfo } from "@/api/landTrustStatistics.js";
export default {
  components: {
    LandCard,
    chart,
  },
  data() {
    return {
      //total: null,
      options: null,
      areaData: [
      ],
    };
  },
  watch: {
    areaData(val, oldval) {
      this.init(val);
    },
    topSelectAreacode() {
      this.getAreaData();
    },
  },
  created() {
    this.getAreaData();
  },
  computed: {
    ...mapGetters(["topSelectAreacode"]),
    total() {
      let res = 0;
      this.areaData.forEach((item) => {
        res = res + item.value;
      });
      res.toFixed(2);
      return { res };
    },
  },
  methods: {
    FloatMul(arg1, arg2) {
      var m = 0,
        s1 = arg1.toString(), //将第一个数据转换成字符出类型
        s2 = arg2.toString(); //将第二个数据转换成字符出类型
      try {
        m += s1.split(".")[1].length; //截取数据的小数部分，得到小数位数
      } catch (e) {}
      try {
        m += s2.split(".")[1].length; //截取数据的小数部分，得到小数位数
        //将两个数据的小数位数长度相加
      } catch (e) {}
      var result =
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m); //将两个数扩大对应的小数位数倍转换成数字类型在相乘，乘完再除于对应的扩大倍数得到最终结果
      return isNaN(result) ? 0 : result;
    },
    async getAreaData() {
      let res = await getTGMJInfo({
        areaCode: this.topSelectAreacode,
      });
      if (res.code == 0) {
        if (res.data) {
          this.areaData = res.data;
          this.areaData.forEach((item) => {
            item.show = true;
          });
        } else {
          this.areaData = [
            {
              show: true,
              name: "全程托管",
              value: 0,
              percent: "0%",
            },
            {
              show: true,
              name: "环节托管",
              value: 0,
              percent: "0%",
            },
          ];
        }
      }
    },
    init(obj) {
      const dataList = obj;
      this.options = {
        xAxis: {
          show: false,
        },
        yAxis: {
          show: false,
        },
        tooltip: {
          trigger: "item",
          showContent: true,
          backgroundColor: "rgba(8,36,68,.7)",
          color: "#fff",
          textStyle: {
            color: "#fff",
          },
          //valueFormatter: (value) => value + " " + "亩",
          formatter: function (params) {
            var html =
              params.marker + "  " + "名称" + "：" + params.name + "<br>";
            html +=
              params.marker +
              "  " +
              "托管面积" +
              "：" +
              params.value +
              "亩" +
              "<br>" +
              params.marker +
              "  " +
              "所占比例" +
              "：" +
              params.percent +
              "%";
            return html;
          },
        },
        title: {
          show: true,
          top: "43%",
          left: "34%",
          textAlign: "center",
          text: "托管总面积" + "\n" + this.total.res.toFixed(2) + "亩",
          textStyle: {
            color: "#333333",
            fontSize: 16,
            fontWeight: "bold",
            lineHeight: 30,
          },
        },
        legend: {
          selectedMode: true, // 图例上的点击事件
          orient: "vertical",
          left: "70%",
          bottom: "20%",
          align: "left",
          itemGap: 12,
          itemWidth: 12, // 设置宽度
          itemHeight: 12, // 设置高度
          //symbolKeepAspect: false,
          textStyle: {
            color: "#000",
            rich: {
              name: {
                verticalAlign: "right",
                align: "left",
                fontSize: 16,
                color: "#71809A",
              },
              value: {
                align: "left",
                fontSize: 18,
                color: "#71809A",
              },
            },
          },
          data: dataList.map((item) => {
            if (item.show) {
              return item.name;
            }
          }),
          formatter: function (name) {
            if (dataList && dataList.length) {
              for (var i = 0; i < dataList.length; i++) {
                if (name === dataList[i].name) {
                  return "{name| " + name + "}  ";
                }
              }
            }
          },
        },
        series: [
          {
            name: "托管面积",
            type: "pie",
            radius: [78, 102],
            center: ["35%", "50%"],
            avoidLabelOverlap: true,
            startAngle: 200,
            label: {
              normal: {
                show: true,
                formatter: (params) => {
                  return params.name;
                },
                //lineHeight: 24,
                textStyle: {
                  //fontSize: 14,
                  fontSize: 16,
                  color: "inherit",
                },
              },
            },
            data: dataList,
          },
        ],
      };
    },
  },
};
</script>

<style lang="less" scoped>
.echartStyle {
  height: calc(100% - 50px);
}
</style>
