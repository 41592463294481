<template>
  <div>
    <HeTong
      ref="hetong"
      v-if="showHt"
      :currentCode="currentCode"
      :currentItem="currentItem"
    />
    <el-dialog
      title="供应信息详情"
      :before-close="handleClose"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <el-form ref="form" label-width="160px">
        <el-form-item label="农服商名称:">
          {{ currentItem.companyName }} <span v-if="currentItem.city">({{ currentItem.city }})</span>
        </el-form-item>
        <el-form-item label="经营场所地址:">
          {{ currentItem.address }}
        </el-form-item>
        <el-form-item label="联系人:">
          {{ currentItem.contactMan }}
        </el-form-item>
        <el-form-item label="联系电话:"> {{ currentItem.phone }} </el-form-item>
        <el-form-item label="意向区域:">{{
          currentItem.intentionRegion
        }}</el-form-item>
        <el-form-item label="作物类型:">
          {{ currentItem.serviceScope }}
        </el-form-item>
        <el-form-item label="托管环节:">
          {{ currentItem.operScope }}
        </el-form-item>
        <el-form-item label="可接管土地面积:">
          {{ currentItem.serviceArea ? currentItem.serviceArea : 0 }}
          亩</el-form-item
        >
        <el-form-item label="已接管土地面积:">
          {{ currentItem.serviceAreaOlde ? currentItem.serviceAreaOlde : 0 }}
          亩</el-form-item
        >
        <el-form-item label="经营场所照片:">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            v-for="(item, index) in currentItem.companyPhoto"
            :key="index"
            :preview-src-list="currentItem.companyPhoto"
          >
          </el-image>
        </el-form-item>
        <el-form-item label="现场照片:">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            v-for="(item, index) in currentItem.xczp"
            :key="index"
            :preview-src-list="currentItem.xczp"
          >
          </el-image>
        </el-form-item>
        <el-form-item label="其他说明:">{{ currentItem.remark }}</el-form-item>
      </el-form>
      <div class="btn" style="text-align: center">
        <el-button @click="back">关闭</el-button>
        <el-button @click="collect">收藏</el-button>
        <el-button @click="Online">在线沟通</el-button>
        <el-button @click="goout" type="primary">我要托管</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import HeTong from "../../quickLink/hetong.vue";
import { SendMessage, companyByid } from "@/api/websocket.js";
import { updateCollection, collectionLand } from "@/api/add.js";
import { log } from 'console';
export default {
  components: {
    HeTong,
  },
  data() {
    return {
      currentCode: 1,
      showHt: false,
      dialogVisible: true,
      baseUrl: window.globalUrl.HOME_API,
      currentItem: {},
      obj: {},
    };
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  watch: {},
  methods: {
    async collect() {
      console.log(this.currentItem);
      if (!this.identityObj.identityTypeCode) {
        this.$message.info("请登录后进行操作");
        return;
      }
      if (this.identityObj.identityTypeCode != 2) {
        this.$message.info("当前账户无权限，请切换农户账户后操作");
        return;
      }
      let res = await  collectionLand({ companyServiceId: this.currentItem.id });
      if (res.code == 0) {
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
    },
    //托管服务商在线沟通

    async Online() {
      // const jgdxq = await companyByid({
      //   id: this.currentItem.id,
      // });
      const jgdxq = this.currentItem;

      let xczp = [];
      if (jgdxq.photos) {
        JSON.parse(jgdxq.photos).forEach((item) => {
          xczp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
        });
      }
      jgdxq.xczp = xczp;

      let companyPhoto = [];
      if (jgdxq.companyPhoto == "" || jgdxq.companyPhoto == null) {
        companyPhoto = [];
      } else if (typeof jgdxq.companyPhoto === "string") {
        const arr = jgdxq.companyPhoto.split(",");
        arr.forEach((item) => {
          companyPhoto.push(
            this.baseUrl + "admin/file/get?ossFilePath=" + item
          );
        });
      } else {
        companyPhoto = jgdxq.companyPhoto;
      }
      jgdxq.companyPhoto = companyPhoto;

      console.log("接管单详情", jgdxq);
      console.log("供应信息详情111-Online");
      if (this.currentItem.authId == this.identityObj.identityId) {
        this.$message.info("本人项目");
      } else {
        let content2 = JSON.stringify(jgdxq);
        console.log("wxf", content2);
        let params = {
          messageList: [
            {
              //消息类型会话托管
              messageType: 5,
            },
          ],
          receiverId: this.currentItem.authId,
          senderType: this.identityObj.identityTypeCode,
          //接受消息人类型只有公司认证用户
          receiverType: 4,
          senderId: this.identityObj.identityId,
          sender: this.identityObj.realName,
          receiver: this.currentItem.companyName,
        };
        let res = await SendMessage(params);
        if (res.code !== 1) {
          this.$router.push({
            path: "/home/Chat",
            query: {
              isshow: true, // 你要传递的参数
              data: jgdxq,
              num: 1,
            },
          });
          // this.$router.push({ name: 'Chat', params: { isshow: true, data: jgdxq, num: 1 } });
        }
      }
    },

    async goout() {
      if (!this.identityObj.identityTypeCode) {
        this.$message.info("请登录后进行操作");
        return;
      }
      if (this.identityObj.identityTypeCode != 2) {
        this.$message.info("当前账户无权限，请切换农户角色后操作");
        return;
      }
      this.showHt = true;
      setTimeout(() => {
        this.$refs.hetong.selectOrder = "";
        this.$refs.hetong.getOrders(this.currentItem);
      }, 100);
      // }
    },

    init(obj) {
      // this.obj = obj;
      // console.log("wxf",val);
      console.log("init", obj);
      let xczp = [];
      let companyPhoto = [];
      if (obj.photos) {
        JSON.parse(obj.photos).forEach((item) => {
          xczp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
        });
      }

      if (obj.companyPhoto == "") {
        companyPhoto = [];
      } else if (typeof obj.companyPhoto === "string") {
        const arr = obj.companyPhoto.split(",");
        arr.forEach((item) => {
          companyPhoto.push(
            this.baseUrl + "admin/file/get?ossFilePath=" + item
          );
        });
      } else {
        companyPhoto = obj.companyPhoto;
      }

      // const str = "苹果,香蕉,橙子,西瓜";

      // obj.companyPhoto.push(this.baseUrl + "admin/file/get?ossFilePath=" + obj.companyPhoto);

      console.log("companyPhoto", companyPhoto);
      console.log("xczp", xczp);
      obj.xczp = xczp;
      obj.companyPhoto = companyPhoto;
      this.currentItem = obj;
    },

    back() {
      console.log("this.currentItem", this.currentItem);
      this.$emit("closeDialog1", false);
    },
    handleClose() {
      this.$emit("closeDialog1", false);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-form-item {
  margin-bottom: 0 !important;
  .el-input {
    width: 260px;
  }
}

/deep/.el-image {
  margin-right: 4px;
}
</style>
