<template>
  <div class="supply">
    <div class="title">
      <h5 :class="isLeftMore == true ? 'leftMore' : ''">{{ title }}</h5>
      <template>
        <slot name="topRight"></slot>
      </template>
    </div>
    <template v-if="isAll">
      <div class="card-body">
        <slot></slot>
      </div>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>
<script>
export default {
  props: ["title", "isAll", "isLeftMore"],
};
</script>
<style lang="less" scoped>
.supply {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  height: 100%;
  .title {
    height: 48px;
    line-height: 48px;
    display: flex;
    //justify-content: space-between;

    h5 {
      padding: 0;
      margin: 0;
      // width: 168px;
      background: url("../../assets/bg-title.jpg") no-repeat right top #0668B3;
      font-size: 18px;
      font-family: Source Han Sans CN;
      color: #fefefe;
      padding-left: 16px;
      padding-right: 40px;

    }
    .leftMore {
      padding-left: 26px;
    }
  }

  .card-body{
    height: calc(100% - 50px);
    width: 100%;
  }
}
</style>
