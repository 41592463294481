<template>
  <card title="生产服务动态">
    <div class="dynamic" @click="liClick($event)">
      <vue-seamless-scroll :data="newsList" :class-option="defaultOption">
        <ul>
          <li v-for="(item, index) in newsList" :key="index">
            <ul class="sub">
              <li
                class="truncate icon"
                v-for="(w, i) in item"
                :key="'sub' + i"
                :title="
                  w.areaName +
                  w.farmerName +
                  '与' +
                  w.companyName +
                  '完成' +
                  w.landArea +
                  '亩土地' +
                  w.orderTypeName
                "
              >
                {{ w.areaName }}{{ w.sex ==2 ? w.farmerName.charAt(0)+'女士' :w.farmerName.charAt(0)+'先生' }}与{{ w.companyName }}完成{{
                  w.landArea
                }}亩土地{{ w.orderTypeName }}
              </li>
            </ul>
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
  </card>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import Card from "@/components/Card/index.vue";
import { getSCFWDT } from "@/api/displayResults.js";

export default {
  components: {
    Card,
    vueSeamlessScroll,
  },

  data() {
    return {
      newsList: [],
      params: {
        current: 1,
        size: 24,
      },
    };
  },

  computed: {
    defaultOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        limitMoveNum: this.newsList.length < 4 ? 4 : this.newsList.length,
      };
    },
  },

  mounted() {
    this.getDynamic();
    //this.init();
  },

  methods: {
    async getDynamic() {
      let res = await getSCFWDT(this.params);

      if (!res || res.code != 0) {
        return;
      }
      this.newsList = res.data.records;
      this.newsList.forEach((item) => {
        if (item.orderType == "1") {
          item.orderTypeName = "全程托管";
        } else {
          item.orderTypeName = "环节托管";
        }
      });
      this.init();
    },
    init() {
      let newArr = [];
      for (let i = 0; i < this.newsList.length / 3; i++) {
        newArr[i] = this.newsList.slice(i * 3, (i + 1) * 3);
      }

      this.newsList = newArr;
    },

    liClick(e) {
      let info = "";
      this.newsList.forEach((ele) => {
        if (ele.id == e.target.dataset.title) {
          info = ele;
        }
      });

      // if (info == 1) {
      //   this.$router.push({ name: "announcementsList" });
      // } else {
      //   this.$router.push({ name: "announcementsInfo", query: { id: info.id } });
      // }
    },
  },
};
</script>

<style lang="less" scope>
ul,
li {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}
.sub {
  display: flex;
  li {
    width: 28%;
    margin-left: 20px;
    vertical-align: module;
    padding: 10px 20px 10px 40px;

    &.icon {
      background: url("../../../assets/icon-msg.png") no-repeat 10px 10px;
    }
  }
}
.dynamic {
  max-height: 200px;
  overflow: hidden;
}
</style>
